


























import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import AddRxMedHeader from '@/components/medications/addRx/MedHeader.vue'
import PrescriptionStrength from '@/models/PrescriptionStrength'
import Pharmacy from '@/models/Pharmacy'

@Component({
  components: {
    AddRxMedHeader,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
    ...mapGetters('addRx', ['pharmacy', 'medStrength']),
  },
})
export default class PharmacyConfirm extends Vue {
  pharmacy!: Pharmacy
  medStrength!: PrescriptionStrength
}
