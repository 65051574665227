import { render, staticRenderFns } from "./PharmacyPrevious.vue?vue&type=template&id=03b9f228&scoped=true&"
import script from "./PharmacyPrevious.vue?vue&type=script&lang=ts&"
export * from "./PharmacyPrevious.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03b9f228",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BFormGroup } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
installComponents(component, {BFormGroup,BListGroup,BListGroupItem})
