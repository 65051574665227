





































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import Pharmacy from '@/models/Pharmacy'

@Component({
  components: {
    Loading,
    LinkArrowIcon,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'hasSucceeded', 'getError']),
    ...mapState('pharmacies', ['pharmacies']),
  },
})
export default class PharmacySelect extends Vue {
  hasSucceeded!: (key: string, id?: string) => boolean
  pharmacies!: Pharmacy[]

  selectPharmacy(pharmacy: Pharmacy) {
    this.$store.commit('addRx/selectPharmacy', { pharmacyId: pharmacy.id })
    this.$router.push({ name: 'delivery' })
  }

  async mounted() {
    await this.$store.dispatch('pharmacies/load')
    if (this.hasSucceeded('pharmacies/load')) {
      if (!this.pharmacies.length) {
        this.$router.replace({ name: 'pharmacy-search' })
      }
    }
  }
}
