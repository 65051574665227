





















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import throttle from 'lodash/throttle'
import { mask } from 'vue-the-mask'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { validationStateMixin } from '@/util/validationState'
import usZipValidator from '@/util/validators/usZipValidator'
import { usPhoneNumber } from '@/util/validators/phoneNumberValidator'
import Loading from '@/components/Loading.vue'
import PhoneInput from '@/components/inputs/PhoneInput.vue'
import PlaceInput from '@/components/inputs/PlaceInput.vue'
import RadioButtons from '@/components/inputs/RadioButtons.vue'
import Autocomplete from '@/components/inputs/autocomplete/Autocomplete.vue'
import AutocompleteListItem from '@/components/inputs/autocomplete/AutocompleteListItem.vue'
import { $t } from '@/i18n'
import User from '@/models/User'
import Pharmacy, { PharmacySearchResult } from '@/models/Pharmacy'

enum PharmacyType {
  retail = 'RETAIL',
  other = 'OTHER',
}

@Component({
  components: { Loading, PhoneInput, PlaceInput, RadioButtons, Autocomplete, AutocompleteListItem },
  directives: { mask },
  mixins: [validationMixin, validationStateMixin],
  computed: {
    ...mapState('user', ['me']),
    ...mapState('pharmacies', ['pharmacies', 'pharmacyResults']),
    ...mapGetters('asyncStatus', ['isInProgress']),
    ...mapGetters('addRx', ['pharmacy']),
  },
  validations: {
    query: { required },
    zipcode: { required, usZipValidator },
    name: { required },
    phone: { required, usPhoneNumber },
  },
})
export default class PharmacySearch extends Vue {
  me!: User
  pharmacy!: Pharmacy | null
  pharmacies!: Pharmacy[]
  isInProgress!: (key: string) => boolean

  typeOptions = [
    {
      value: PharmacyType.retail,
      text: $t('Retail Pharmacy'),
    },
    {
      value: PharmacyType.other,
      text: $t('Other (Mail/Online)'),
    },
  ]

  pharmacyType = this.typeOptions[0].value

  // retail
  query: null | string = ''
  zipcode: null | string = null

  // other
  name: null | string = null
  phone: null | string = null

  throttledSearch = throttle(this.search, 500, { leading: true, trailing: true })

  get isRetail() {
    return this.pharmacyType === PharmacyType.retail
  }

  get loading() {
    return (
      this.isInProgress('pharmacies/search') ||
      this.isInProgress('pharmacies/getDetails') ||
      this.isInProgress('pharmacies/add')
    )
  }

  @Watch('me', { immediate: true })
  onUserChanged(user: User) {
    this.zipcode = user.shippingAddress?.zipcode?.slice(0, 5) || null
  }

  @Watch('pharmacy', { immediate: true })
  onPharmacyChanged(pharmacy: Pharmacy) {
    if (!pharmacy) return
    this.query = pharmacy.name
  }

  @Watch('zipcode')
  onZipcodeChanged(zip: string) {
    if (zip.length < 5 || !this.query) return
    this.throttledSearch(this.query)
  }

  async search(name: string) {
    if (this.$v.query) this.$v.query.$touch()
    if (this.$v.zipcode) this.$v.zipcode.$touch()
    if (this.$v.query?.$invalid || this.$v.zipcode?.$invalid) return

    this.$store.dispatch('pharmacies/search', { name, zipcode: this.zipcode })
  }

  async submitRetail(pharmacySearchResult: PharmacySearchResult) {
    const pharmacyDetails = await this.$store.dispatch('pharmacies/getDetails', {
      pharmacy: pharmacySearchResult,
    })
    const pharmacy = await this.$store.dispatch('pharmacies/add', { pharmacy: pharmacyDetails })

    this.$store.commit('addRx/selectPharmacy', { pharmacyId: pharmacy.id })
    this.$router.push({
      name: 'pharmacy-confirm',
    })
  }

  async submitOther() {
    if (this.$v.name) this.$v.name.$touch()
    if (this.$v.phone) this.$v.phone.$touch()
    if (this.$v.name?.$invalid || this.$v.phone?.$invalid) return

    const pharmacy = await this.$store.dispatch('pharmacies/add', {
      pharmacy: new Pharmacy({
        name: this.name as string,
        phoneNumber: this.phone as string,
      }),
    })
    this.$store.commit('addRx/selectPharmacy', { pharmacyId: pharmacy.id })
    this.$router.push({
      name: 'pharmacy-confirm',
    })
  }
}
