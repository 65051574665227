




import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class PlaceInput extends Vue {
  @Prop(String)
  value!: string
}
