































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import AddRxMedHeader from '@/components/medications/addRx/MedHeader.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import PrescriptionStrength from '@/models/PrescriptionStrength'

@Component({
  components: {
    AddRxMedHeader,
    LinkArrowIcon,
  },
  computed: {
    ...mapGetters('addRx', ['medStrength']),
  },
})
export default class PharmacyPrevious extends Vue {
  medStrength!: PrescriptionStrength

  selectYes() {
    this.$store.commit('addRx/selectHasPharmacy')

    this.$router.push('/add-medication/pharmacy/select')
  }

  selectNo() {
    this.$store.commit('addRx/selectNoPharmacy')

    this.$router.push('/add-medication/delivery')
  }
}
