
















import { Component, Vue, Prop } from 'vue-property-decorator'
import { TheMask } from 'vue-the-mask'
import { phoneNumberMask } from '@/util/validators/phoneNumberValidator'

@Component({
  components: {
    TheMask,
  },
})
export default class PhoneInput extends Vue {
  focused = false

  @Prop({ type: String, default: '' })
  value?: string

  @Prop({ type: Boolean, default: null })
  state?: boolean | null

  get phoneNumberMask(): string {
    return phoneNumberMask(this.value as string)
  }

  get stateClass(): string {
    // add bootstrap input state class
    return this.state === false ? 'is-invalid' : ''
  }

  focus() {
    const mask = this.$refs.maskedInput as Vue
    const $input = mask.$el as HTMLInputElement
    $input.focus()
  }
}
