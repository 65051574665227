




































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { validationStateMixin } from '@/util/validationState'
import { usPhoneNumber } from '@/util/validators/phoneNumberValidator'
import Loading from '@/components/Loading.vue'
import PhoneInput from '@/components/inputs/PhoneInput.vue'
import Pharmacy from '@/models/Pharmacy'

@Component({
  components: {
    PhoneInput,
    Loading,
  },
  mixins: [validationMixin, validationStateMixin],
  validations: {
    name: { required },
    phone: { required, usPhoneNumber },
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class PharmacyAddManually extends Vue {
  isInProgress!: (key: string) => boolean
  name = ''
  phone = ''

  async submit() {
    this.$v.$touch()
    if (this.$v.$invalid) {
      return false
    }
    const pharmacy = new Pharmacy({
      name: this.name,
      phoneNumber: this.phone,
    })
    const savedPharmacy = await this.$store.dispatch('pharmacies/add', { pharmacy })
    this.$store.commit('addRx/selectPharmacy', { pharmacyId: savedPharmacy.id })
    this.$router.push({
      name: 'pharmacy-confirm',
    })
    return true
  }

  get loading() {
    return this.isInProgress('pharmacies/add')
  }
}
